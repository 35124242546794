<template>
  <div class="in-app-messaging-banner" :style="bannerStyles">
    <div class="in-app-messaging-banner__wrapper">
      <div class="in-app-messaging-banner__text">{{ bannerTextLocalized }}</div>
      <div style="margin-left: auto">
        <vs-button
          class="in-app-messaging-banner__button"
          :style="callToActionButtonStyles"
          :color="callToActionButtonStyles.color"
          :href="'//' + bannerCallToActionButtonLinkLocalized"
          target="_blank"
          v-if="banner.isCallToActionButtonEnabled"
        >
          {{ bannerCallToActionButtonTextLocalized }}
        </vs-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
const dayjs = require('dayjs')
const isSameOrAfter = require('dayjs/plugin/isSameOrAfter')
const isBetween = require('dayjs/plugin/isBetween')
dayjs.extend(isSameOrAfter)
dayjs.extend(isBetween)

export default {
  name: 'InAppMessagingBanner',
  props: {
    banner: {
      type: Object
    }
  },
  data() {
    return {
      currentDay: dayjs()
    }
  },
  computed: {
    ...mapGetters({
      activeUserInfo: 'activeUser'
    }),
    bannerStyles() {
      return {
        backgroundColor: this.banner.background || '#3b86f7',
        color: this.banner.color || '#FFFFFF',
        backgroundImage: `url(${this.banner.backgroundImage})` || '',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        opacity: this.isExpiredState || !this.banner.selectedAudience.length || !this.banner.isEnabled ? '0.3' : '1'
      }
    },
    bannerCallToActionButtonLinkLocalized() {
      const locale = this.$i18n.locale
      return this.banner.callToActionButtonLink[locale] || this.banner.callToActionButtonLink.en
    },
    bannerTextLocalized() {
      const locale = this.$i18n.locale
      return this.banner.text[locale] || this.banner.text.en
    },
    bannerCallToActionButtonTextLocalized() {
      const locale = this.$i18n.locale
      return this.banner.callToActionButtonText[locale] || this.banner.callToActionButtonText.en
    },
    callToActionButtonStyles() {
      return {
        backgroundColor: this.banner.callToActionButtonBackground || '#F0A941',
        color: this.banner.callToActionButtonColor || '#FFFFFF'
      }
    },
    isExpiredState() {
      if (this.banner && this.banner.dateEnd) {
        const startedAt = dayjs(this.banner.dateStart)
        const expiresAt = dayjs(this.banner.dateEnd)
        const isExpired = dayjs().isSameOrAfter(expiresAt, 'day')
        if (!isExpired) {
          if (startedAt) {
            const isBetween = this.currentDay.isBetween(startedAt, expiresAt)
            if (isBetween) {
              return false
            }
            return true
          }
          return false
        }
      }
      return true
    }
  }
}
</script>
<style lang="scss" scoped>
.in-app-messaging-banner {
  position: relative;
  padding: 7px 25px 7px 40px;
  width: 100%;
  display: flex;
  min-height: 44px;
  &__wrapper {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
  }
  &__text {
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    max-width: 950px;
    margin-right: 12px;
  }
  &__button:not(.vs-radius):not(.includeIconOnly):not(.small):not(.large) {
    font-size: 16px;
    font-weight: 600;
    padding: 0 24px;
    min-height: 30px;
  }
}
</style>
