var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "in-app-messaging-banner", style: _vm.bannerStyles },
    [
      _c("div", { staticClass: "in-app-messaging-banner__wrapper" }, [
        _c("div", { staticClass: "in-app-messaging-banner__text" }, [
          _vm._v(_vm._s(_vm.bannerTextLocalized)),
        ]),
        _c(
          "div",
          { staticStyle: { "margin-left": "auto" } },
          [
            _vm.banner.isCallToActionButtonEnabled
              ? _c(
                  "vs-button",
                  {
                    staticClass: "in-app-messaging-banner__button",
                    style: _vm.callToActionButtonStyles,
                    attrs: {
                      color: _vm.callToActionButtonStyles.color,
                      href: "//" + _vm.bannerCallToActionButtonLinkLocalized,
                      target: "_blank",
                    },
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.bannerCallToActionButtonTextLocalized) +
                        "\n      "
                    ),
                  ]
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }